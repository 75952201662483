// src/slices/shipmentSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Shipment, ShipmentState } from './types';

const initialState: ShipmentState = {
  shipmentInfo: {
    trailerType: '',
    truckType: '',
    customId: '',
    id: '',
    projectId: '',
    trackId: '',
    truckTypeId: '',
    trailerId: '',
    note: '',
    loads: [
      {
        packageType: '',
        productType: '',
        productTypeId: '',
        loadType: '',
        paletteTypeList: [],
        packageTypeList: [],
        trailerTypeList: [],
        authorityList: [],
        packageTypeId: '',
        palletteCount: '',
        width: '',
        length: '',
        height: '',
        weight: '',
        departureTime: '',
        departureAddressId: '',
        departureAddress: '',
        departureStartAt: '',
        departureEndAt: '',
        addLoadingAuthority: false,
        departureAddressAuthorityId: '',
        arrivalAddresses: [
          {
            arrivalAddressId: '',
            arrivalAddress: '',
            arrivalTime: '',
            arrivalStartAt: '',
            arrivalEndAt: '',
            addDeliveryAuthority: false,
            arrivalAuthorityList: [],
            arrivalAddressAuthorityId: '',
            isAuthorityRequired: false
          }
        ]
      }
    ],
    status: '',
    driverId: '',
    vehicleId: ''
  },
  loadRoute: [],
  arrivalRoute: [],
  needRefetch: false
};

export const shipmentSlice = createSlice({
  name: 'shipment',
  initialState,
  reducers: {
    reset: () => initialState,
    setFtlInfo: (state, action: PayloadAction<Shipment>) => {
      state.shipmentInfo = action.payload;
    },
    returnFtlInfo: (state, action: PayloadAction<Shipment>) => {
      state.shipmentInfo = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setLoadRoute: (state, action: PayloadAction<any[]>) => {
      state.loadRoute = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setArrivalRoute: (state, action: PayloadAction<any[]>) => {
      state.arrivalRoute = action.payload;
    },
    needRefecth: (state) => {
      state.needRefetch = true;
    },
    resetRefetch: (state) => {
      state.needRefetch = false;
    }
  }
});

export const { setFtlInfo, setLoadRoute, setArrivalRoute, needRefecth, resetRefetch } = shipmentSlice.actions;

export default shipmentSlice.reducer;
